
import { Component, Vue } from 'vue-property-decorator'
import AddToilet from './AddToilet.vue'
import { ToiletDetail } from '../../types/toilet.d'
@Component({
  name: 'DetailList',
  components: { AddToilet }
})
export default class DetailList extends Vue {
  private loading = false
  private list: Array<ToiletDetail> = []
  page = 1
  size = 10
  total = 0
  isAdd = false
  toiletId = ''

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<ToiletDetail> }>(this.$apis.toilet.selectSmartToiletByPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.list = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onEdit (id: string) {
    this.toiletId = id
    this.isAdd = true
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.toilet.deleteSmartToilet, {
        toiletId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
